import { useFetcher } from "@remix-run/react";
import { useCallback } from "react";
import type { SessionData } from "~types/app/session.types";

import { useManagedFetcher } from "./use-managed-fetcher";

type HandleComparatorChangeParams = {
  id: number;
  categorySlug: string;
  checked: boolean;
};

export const useComparator = ({ comparator }: { comparator?: SessionData["comparator"] }) => {
  const comparatorFetcher = useFetcher<any>();
  const clearComparatorFetcher = useFetcher<any>();

  const [isComparatorFetcherLoading, isComparatorFetcherDone, isComparatorFetcherFailed] =
    useManagedFetcher({
      fetcher: comparatorFetcher,
      toastSuccessMessage: comparatorFetcher.data?.message || "Comparateur mis à jour",
    });

  useManagedFetcher({
    fetcher: clearComparatorFetcher,
    toastSuccessMessage: clearComparatorFetcher.data?.message || "Comparateur vidé",
  });

  const handleComparatorProductChange = useCallback(
    ({ id, categorySlug, checked }: HandleComparatorChangeParams) => {
      comparatorFetcher.submit(
        {
          itemType: "product",
          id: id.toString(),
          checked: checked.toString(),
          categorySlug,
        },
        {
          method: "post",
          action: "/account/comparator/update",
        }
      );
    },
    [comparatorFetcher]
  );

  const handleComparatorRangeChange = useCallback(
    ({ id, checked }: HandleComparatorChangeParams) => {
      comparatorFetcher.submit(
        {
          itemType: "range",
          id: id.toString(),
          categorySlug: "systemes-de-fixation",
          checked: checked.toString(),
        },
        {
          method: "post",
          action: "/account/comparator/update",
        }
      );
    },
    [comparatorFetcher]
  );

  const handleClearComparator = useCallback(() => {
    clearComparatorFetcher.submit(
      {},
      {
        method: "post",
        action: "/account/comparator/clear",
      }
    );
  }, [clearComparatorFetcher]);

  const isInComparator = useCallback(
    (productId: number) => {
      return comparator?.products?.includes(productId);
    },
    [comparator]
  );

  return {
    handleComparatorProductChange,
    handleComparatorRangeChange,
    handleClearComparator,
    isInComparator,
    isComparatorFetcherLoading,
    isComparatorFetcherDone,
    isComparatorFetcherFailed,
  };
};
